
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .precision {
  display: inline-block;
  color: #3c3;
  // text-decoration: underline;
  margin: 0;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #3c3;
  }
}

::v-deep .technical_reasoning {
  display: inline-block;
  color: #f93;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #f93;
  }
}

::v-deep .sequence {
  display: inline-block;
  color: #39c;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  transition: 0.5s;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #39c;
  }
}

::v-deep .confluence {
  display: inline-block;
  color: #c36;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #c36;
  }
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
.letter {
  font-size: 40px;
}
// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  display: none;
  @media print {
    display: block !important;
  }
  .main_container {
    .main_auth_user_info {
      text-align: center;
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .n_name {
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .n_email {
        font-size: 22px;
      }
      .classification_classification {
        padding: 0 36px;
        min-width: 180px;
        border-radius: 12px;
        background-color: #00563f;
        height: 45px;
        display: flex;
        align-items: center;
        width: fit-content;
        color: #fff;
        margin: 10px auto;
      }
      // Below Score Cards
    }
    .the_patterns_with_new_theme {
      position: relative;
      .new_theme_pattern_content {
        // display: none;
        display: block;
        @media print {
          display: block !important;
        }
        padding: 0 10px;
        .main_content_container {
          border-left: 2px solid;
          border-right: 2px solid;
          border-bottom: 2px solid;
          text-align: center;
          padding: 10px 5px;
          &.with_sequence {
            border-color: #39c;
          }
          &.with_precision {
            border-color: #3c3;
          }
          &.with_technical_reasoning {
            border-color: #f93;
          }
          &.with_confluence {
            border-color: #c36;
          }
          .ti {
            // color: #555;
            font-size: 15px;
          }
          .te {
            color: #777;
            font-size: 13px;
          }
        }
      }
    }
    .wrapper_for_use_first {
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .main_co {
        background-color: #eee;
        display: flex;
        justify-content: center;
        gap: 10px;
        color: #7d7979;
        padding: 15px;
        .a_one {
          &.with_title {
            color: #555;
          }
        }
        .a_two {
          border-left: 3px solid #777;
          border-right: 3px solid #777;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .main_collapse_title_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .every_collapse_title {
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 18px;
        }
        @media (min-width: 320px) and (max-width: 350px) {
          font-size: 11px;
        }
      }
      .every_collapse_button {
        color: #fff;
        width: 50px;
        height: 50px;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 36px;
          height: 36px;
        }
      }
      &.is_rtl {
        flex-direction: row-reverse;
        padding-right: 10px;
      }
    }
    .main_strategy_container {
      display: flex;
      gap: 4%;
      page-break-inside: avoid;
      @media (min-width: 320px) and (max-width: 991px) {
        flex-wrap: wrap;
      }
      @media print {
        flex-wrap: nowrap;
        margin-top: 40px !important;
        page-break-after: always;
      }
      .cms_strategy_wrapper {
        width: calc(96% / 2);
        padding: 15px;
        color: #fff;
        border-radius: 10px;
        background-color: #00563f;
        min-height: 170px;
        @media (min-width: 320px) and (max-width: 991px) {
          margin-bottom: 20px;
        }
      }
    }
    .set_bg_white_for_share {
      background: #fff;
    }
    .alert_for_collapse {
      font-size: 1.2rem;
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 1rem;
      }
      /* Define custom class for page break */
      .print-page-break {
        page-break-inside: avoid;
      }
    }
    .el-collapse {
      background: #fff;
    }
    .wrapper_check_speech {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .icons_style {
      background-color: #eee;
      // box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      font-size: 14px;
      width: 30px;
      height: 30px;
      // box-shadow: $simple-shadow;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #777;
      transition: 0.3s;
      &.speech {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }
      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-checkbox {
  text-transform: capitalize !important;
  display: flex;
  &.check_box_with_rtl {
    flex-direction: row-reverse;
    margin-right: 0;
    align-items: center;
    gap: 3px;
  }
}

::v-deep .el-checkbox__label {
  word-break: break-word !important;
  white-space: pre-wrap;
  // word-wrap: break-word;
  white-space: normal;
  color: #000;
  padding: 0 7px;
  text-transform: initial;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #000;
}
::v-deep .texst {
  word-break: break-word !important;
  word-wrap: break-word;
  white-space: normal;
  // white-space: pre-wrap;
  text-transform: initial;
  font-size: 1.2rem;
  line-height: 1;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

.child.v-expansion-panel--active > button {
  background-color: #26a1da !important;
  color: #fff;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}
::v-deep .el-card__body {
  display: none;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  .hide_me_printable {
    display: none !important;
  }
  .looping_tasks_here {
    page-break-inside: avoid;
  }
  .print-page-break {
    page-break-after: always;
  }
}
/* Add print styles to control page breaks */
@media print {
  .print-page-break {
    page-break-after: always;
  }
}
